@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Variable.ttf");
}
/* Enable smooth scrolling for all anchor links */
a {
  scroll-behavior: smooth;
}

/* Optional: Define the scroll speed and easing function */
/* You can adjust these values as needed */
body,
html {
  scroll-behavior: smooth;
  scroll-behavior: smooth !important;
  scroll-snap-type: y mandatory;
  scroll-snap-points-y: repeat(100%);
}
